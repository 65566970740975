import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/uguryildirim/git/eteration/ui-components/node_modules/gatsby-theme-docz/src/base/Layout.js";
import Playground from 'components/Playground';
import Card from "components/Card";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "card"
    }}>{`Card`}</h1>

    <Playground __position={0} __code={'<div style={{ width: \'340px\' }}>\n  <Card>\n    <Card.Header>Header</Card.Header>\n    <Card.Img\n      top\n      width=\"100%\"\n      src=\"https://reactstrap.github.io/assets/318x180.svg\"\n      alt=\"Card image cap\"\n    />\n    <Card.Body>\n      <Card.Title tag=\"h5\">Card title</Card.Title>\n      <Card.Subtitle tag=\"h6\" className=\"mb-2 text-muted\">\n        Card subtitle\n      </Card.Subtitle>\n      <Card.Text>\n        Some quick example text to build on the card title and make up the\n        bulk of the card\'s content.\n      </Card.Text>\n    </Card.Body>\n    <Card.Footer>Footer</Card.Footer>\n  </Card>\n</div>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Card,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div style={{
        width: '340px'
      }}>
    <Card mdxType="Card">
      <Card.Header>Header</Card.Header>
      <Card.Img top width="100%" src="https://reactstrap.github.io/assets/318x180.svg" alt="Card image cap" />
      <Card.Body>
        <Card.Title tag="h5">Card title</Card.Title>
        <Card.Subtitle tag="h6" className="mb-2 text-muted">Card subtitle</Card.Subtitle>
        <Card.Text>Some quick example text to build on the card title and make up the bulk of the card's content.</Card.Text>
      </Card.Body>
      <Card.Footer>Footer</Card.Footer>
    </Card>
  </div>
    </Playground>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <p><a href="https://reactstrap.github.io/components/card/" target="_blank">Reactstrap Card</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      